

.settingsPageWrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 50px);
    max-width: 440px;
    margin: auto;
}

.settingsTabHeaderWrapper {
    height: 50px;
    display: grid;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    position: relative;
    margin: 0px 10px;
}

.settingsTabHeaderBackButton {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    margin-top: 1px;
    border: 0px;
    font-size: 16px;
    padding: 0px;
    height: 49px;
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
}

.settingsTabHeaderBackArrow {
    padding-right: 5px;
    font-size: 20px;
}

.settingsTabHeaderText {
    font-size: 16px;
    color: var(--text-color);
    font-weight: 600;
}

.settingsPagePadding {
    padding: 10px 10px;
    max-width: 425px;
    margin: auto;
}

.settingsPageHeader {
    margin: 12px 0px;
    font-size: 18px;
    color: var(--text-color);
    font-weight: 600;
}

.settingsUnderlinedHeader {
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    margin: 10px 0px;
    margin-top: 5px;
    padding-bottom: 12px;
}

.settingsPageOtherHeader {
    padding-left: 10px;
    max-width: 425px;
    margin: 12px auto;
    margin-top: 0px;
    font-weight: 600;
}

.modeContainer {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 0px;
    border-radius: 2px;
}

.modeContainerDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.modeButton {
    background-color: var(--secondary-background-color);
    border: 1px solid var(--primary-color);
    color: var(--text-color);
    padding: 10px 0px;
    cursor: pointer;
    margin: 0px;
}

.modeButton:nth-child(1) {
    border-radius: 2px 0px 0px 2px;
    border-right: 0px;
}

.modeButton:nth-last-child(1) {
    border-radius: 0px 2px 2px 0px;
    border-left: 0px;
}

.modeButtonSelected {
    color: white;
    background-color: var(--primary-color);
}

.settingsPageOtherWrapper {
    width: 100%;
    height: fit-content;
    max-width: 425px;
    margin: auto;
    min-height: calc(100vh - 529px);
    border-top: 1px solid rgba(112, 112, 112, 0.4);
    padding-bottom: 10px;
}

.settingsPageOtherOption {
    height: 45px;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    background-color: rgba(0, 0, 0, 0);
    color: var(--text-color);
    text-align: start;
    padding-left: 10px;
    padding-right: 0px;
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    transition-duration: 100ms;
}

.settingsPageOtherOption:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.settingPageOtherArrow {
    width: 40px;
}

.settingPageOtherArrow {
    position: relative;
    justify-self: end;
    align-self: start;
    margin-top: 3px;
    height: 29px;
    width: 30px;
}

.settingPageOtherArrow::before, .settingPageOtherArrow::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgb(200, 200, 200);
    height: 10px;
    width: 2px;
    border-radius: 2px;
}

.settingPageOtherArrow::before {
    transform: rotate(-45deg) translateY(-45%);
}

.settingPageOtherArrow::after {
    transform: rotate(45deg) translateY(43%);
}

.settingsLogoutWrapper {
    position: sticky;
    bottom: 10px;
    left: 10px;
    /* transform: translateX(-50%); */
    width: calc(100% - 20px);
    height: fit-content;
    max-height: 60px;
    max-width: 425px;
    display: grid;
    align-items: center;
    justify-items: center;
    padding-top: 10px;
}

.settingsLogoutButton {
    height: 40px;
    width: 100%;
}

.settingsPageAccountDetailsTitle {
    margin: 2px 0px;
    font-size: 12px;
    opacity: 0.45;
    color: var(--text-color);
    font-weight: 600;
}

.settingsPageAccountDetailsInfo {
    margin: 0px 0px 15px 0px;
    font-size: 16px;
    color: var(--text-color);
}

.settingsResetDataButton {
    position: absolute;
    bottom: 10px;
    height: 40px;
    width: calc(100% - 20px);
    margin-left: 10px;
    border: 0px;
    background-color: red;
    color: white;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.bugReportLabel {
    /* display: inline-block !important; */
    margin: 10px 10px 5px 10px !important;
}

.bugReportTextArea {
    resize: none;
    height: calc(100% - 90px) !important;
    width: calc(100% - 40px) !important;
    max-height: 250px;
    padding: 10px !important;
}

.settingsColorPickerWrapper {
    height: fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 25px;
}

.offlineText {
    text-align: center;
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.settingsToggleItemWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 12px;
}

.settingsToggleItemWrapper:nth-last-child(1) {
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    padding-bottom: 8px;
}

.settingsSyncWithServerText {
    margin: 0px 10px;
    margin-top: 10px;
    color: var(--text-color);
    font-weight: 600;
}

.settingsSyncWithServerSubtext {
    margin: 0px 10px;
    margin-top: 5px;
    font-size: 14px;
    opacity: 0.7;
    color: var(--text-color);
}

.settingsSyncButton {
    margin: 0px 10px;
    width: calc(100% - 20px);
    height: 35px;
    margin-top: 10px;
}
